import { AgGrid, ETFCard, Layout, Utils } from '@cfra-nextgen-frontend/shared';
import { AgGirdExportButton, AgGridCard } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { CardWithAgGridTopPanel } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/CardWithAgGridTopPanel';
import { getCompanyDetailsLinkRenderer } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/renderers';
import { BreadcrumbConfig, ColumnDef } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/types';
import { ChartTabsProps } from '@cfra-nextgen-frontend/shared/src/components/Chart/ChartTabs';
import { NoInformationAvailable } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { PageWithBreadcrumbsInHeaderContext } from '@cfra-nextgen-frontend/shared/src/components/PageWithBreadcrumbsInHeader/PageWithBreadcrumbsInHeaderContext';
import {
    Categories,
    MarketTrendsDateRanges,
    ValueTypes,
    categoriesToAggregateField,
    categoriesToResponceCategoryField,
    dateRangeToFlowToAssetRatioDataPoint,
    dateRangeToNetFlowsDataPoint,
    dateRangeToReturnSplitAndDividendDataPoint,
    marketTrendsDateRangesToDisplayString,
    valuesTypesToExcelNumberFormat,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { Box } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { exportAgGrid, exportSSRAgGrid } from 'components/excelExport/export';
import { forwardRef, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useLocation } from 'react-router-dom';
import { getAggregationFlows, getAggregationPerformance, getKeyAssetClassesData } from '../api/market';
import { MarketTrend, MarketTrendsDetailsProps } from '../types/market';
import {
    ETFFlowsToAssetThemesAndFactorsData,
    ETFPerformanceThemesAndFactorsData,
    KeyAssetClassesData,
} from '../types/research';
import {
    tickerDisplayName as keyAssetClassesTickerDisplayName,
    tickerExchanges as keyAssetClassesTickerExchanges,
} from './charts/KeyAssetClasses';
import {
    tickerDisplayName as SectorPerformanceTickerDisplayName,
    tickerExchanges as SectorPerformanceTickerExchanges,
} from './charts/SectorPerformance';
import { prefetchData, prefetchFunctionProps } from './charts/shared/utils';
import { getDrillDownRenderer, getEtfsListOverlayRenderer } from './shared/renderers';
import { DrillDownProps } from './shared/types';
import { getAsOfDateMax, getAsOfDatePath, getMinWidthForHeader, getNextLevelCategory } from './shared/utils';

export type MarketTrendsDetailsLocationState = {
    drillDownProps: DrillDownProps;
    selectedDateRange: MarketTrendsDateRanges;
    selectedCategory: Categories;
};

const customFlexibleColumns = [Categories.CategoryOne, Categories.CategoryTwo, 'ETF Name'];

const performanceColumnsDefs: Array<ColumnDef> = [
    {
        headerName: 'YTD Return',
        field: 'performance.split_and_dividend_ytd_return',
        valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.Percentage),
        type: 'rightAligned',
        excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.Percentage],
    },
    {
        headerName: '1 Month Return',
        field: 'performance.month_1_return',
        valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.Percentage),
        type: 'rightAligned',
        excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.Percentage],
    },
    {
        headerName: '3 Month Return',
        field: 'performance.month_3_return',
        valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.Percentage),
        type: 'rightAligned',
        excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.Percentage],
    },
    {
        headerName: '1 Year Return',
        field: 'performance.return_split_and_dividend_one_year',
        valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.Percentage),
        type: 'rightAligned',
        excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.Percentage],
    },
];

const flowsColumnsDefs: Array<ColumnDef> = [
    {
        headerName: 'YTD Flows ($M)',
        field: 'performance.net_flows_ytd',
        valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.MillionsWithForceScale),
        type: 'rightAligned',
        excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.MillionsWithForceScale],
    },
    {
        headerName: '1 Month Flows ($M)',
        field: 'performance.net_flows_one_month',
        valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.MillionsWithForceScale),
        type: 'rightAligned',
        excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.MillionsWithForceScale],
    },
    {
        headerName: '3 Month Flows ($M)',
        field: 'performance.net_flows_three_month',
        valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.MillionsWithForceScale),
        type: 'rightAligned',
        excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.MillionsWithForceScale],
    },
    {
        headerName: '1 Year Flows ($M)',
        field: 'performance.net_flows_one_year',
        valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.MillionsWithForceScale),
        type: 'rightAligned',
        excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.MillionsWithForceScale],
    },
];

const containerStyles = { paddingTop: '36px' };

export const MarketTrendsDetails = forwardRef<React.ReactNode, MarketTrendsDetailsProps>(({ label }, ref) => {
    const { state }: { state: MarketTrendsDetailsLocationState } = useLocation();

    const [drillDownProps, setDrillDownProps] = useState<DrillDownProps | null>(
        state && state.drillDownProps ? state.drillDownProps : null,
    );

    const [selectedDateRange, setSelectedDateRange] = useState<MarketTrendsDateRanges>(
        state && state.selectedDateRange ? state.selectedDateRange : MarketTrendsDateRanges.ThreeMonth,
    );
    const [selectedCategory, setSelectedCategory] = useState<Categories>(
        state && state.selectedDateRange ? state.selectedCategory : Categories.CategoryOne,
    );

    const [dateRangesPanelConfig, setDateRangesPanelConfig] = useState<
        Layout.ETFButtonsPannelButtonProps[] | undefined
    >(undefined);
    const [categoriesPanelConfig, setCategoriesPanelConfig] = useState<ChartTabsProps | undefined>(undefined);

    const gridRef = useRef<AgGridReact>(null);
    const { setRightSideSlotContent } = useContext(PageWithBreadcrumbsInHeaderContext);

    const [rowsData, setRowsData] = useState<Array<any>>();

    useEffect(() => {
        setRightSideSlotContent(
            <Box sx={{ paddingTop: '42px', paddingRight: '28px' }}>
                <AgGirdExportButton
                    label={label}
                    exportRef={gridRef}
                    excelExportAsOfDateField={getAsOfDatePath(label)}
                    excelExportUseMaxAsOfDate={getAsOfDateMax(label)}
                    dateRangesPanelConfig={dateRangesPanelConfig}
                    selectedCategory={selectedCategory}
                    exportAgGrid={exportAgGrid}
                    exportSSRAgGrid={exportSSRAgGrid}
                />
            </Box>,
        );
    }, [dateRangesPanelConfig, label, selectedCategory, setRightSideSlotContent, rowsData]);

    const [dynamicColumnDefs, setDynamicColumnDefs] = useState<Array<ColumnDef>>([]);

    const etfsListOverlayRenderer = useMemo(
        () => getEtfsListOverlayRenderer(label, selectedDateRange, selectedCategory, drillDownProps),
        [label, selectedDateRange, selectedCategory, drillDownProps],
    );
    const companyDetailsLinkRenderer = useMemo(
        () =>
            getCompanyDetailsLinkRenderer({
                cfraIdPath: [MarketTrend.KeyAssetClassesDetails, MarketTrend.SectorPerformanceDetails].includes(label)
                    ? 'id'
                    : 'cfra_id',
                cardName: label,
                categoryLevel: selectedCategory,
                dateRange: selectedDateRange,
            }),
        [label, selectedCategory, selectedDateRange],
    );

    const drillDownRenderer = useMemo(
        () =>
            getDrillDownRenderer({
                drillDownProps,
                setDrillDownProps,
                setSelectedCategory,
                label,
                selectedDateRange,
                selectedCategory,
            }),
        [drillDownProps, setDrillDownProps, setSelectedCategory, label, selectedDateRange, selectedCategory],
    );

    useEffect(() => {
        switch (label) {
            case MarketTrend.FlowsToAssetsDetails:
            case MarketTrend.PerformanceDetails:
                const assetClassColumn = {
                    headerName: Categories.AssetClass,
                    field: 'asset_class',
                    cellRenderer: drillDownRenderer,
                };
                const cfraCategoryColumn = {
                    headerName: Categories.CategoryOne,
                    field: 'cfra_level_1',
                    cellRenderer: drillDownRenderer,
                };
                const cfraSubCategoryColumn = {
                    headerName: Categories.CategoryTwo,
                    field: 'level_2_name',
                    cellRenderer: etfsListOverlayRenderer,
                };

                switch (selectedCategory) {
                    case Categories.AssetClass:
                        setDynamicColumnDefs([assetClassColumn]);
                        break;
                    case Categories.CategoryOne:
                        setDynamicColumnDefs([assetClassColumn, cfraCategoryColumn]);
                        break;
                    case Categories.CategoryTwo:
                        setDynamicColumnDefs([assetClassColumn, cfraCategoryColumn, cfraSubCategoryColumn]);
                        break;
                }

                const registerAction = (action: string) =>
                    globalThis.analytics?.registerAction?.({
                        action: action,
                        cardName: label,
                        dateRange: selectedDateRange,
                        selectedCategory: drillDownProps ? 'no categories selected' : selectedCategory,
                    });

                setDateRangesPanelConfig(
                    Object.values(MarketTrendsDateRanges).map((dateRange) => ({
                        name: dateRange,
                        callback: () => {
                            registerAction(`date range selection : ${dateRange}`);
                            setSelectedDateRange(dateRange as MarketTrendsDateRanges);
                        },
                        disabled: false,
                        isDefault: dateRange === selectedDateRange,
                    })),
                );

                setCategoriesPanelConfig({
                    tabs: Object.values(Categories),
                    currentActiveTab: drillDownProps ? false : Object.values(Categories).indexOf(selectedCategory),
                    handleTabChange: (event: React.SyntheticEvent, newValue: number) => {
                        if (drillDownProps) setDrillDownProps(null);
                        const newCategory = Object.values(Categories)[newValue];
                        registerAction(`category selection : ${newCategory}`);
                        setSelectedCategory(newCategory);
                    },
                });

                break;
        }
    }, [
        label,
        selectedCategory,
        selectedDateRange,
        drillDownProps,
        etfsListOverlayRenderer,
        drillDownRenderer,
        setSelectedCategory,
    ]);

    const etfNameColumnDef = useMemo(
        () => ({
            headerName: 'ETF Name',
            field: 'composite_name',
            cellRenderer: companyDetailsLinkRenderer,
        }),
        [companyDetailsLinkRenderer],
    );

    const tickerColumnDef = useMemo(
        () => ({
            headerName: 'Ticker',
            field: 'composite_ticker',
            cellRenderer: companyDetailsLinkRenderer,
        }),
        [companyDetailsLinkRenderer],
    );

    const numberOfEtfsColumnDef = useMemo(
        () => ({
            headerName: 'Number of ETFs',
            field: 'etf_count',
            type: 'rightAligned',
            flex: 1,
            cellRenderer: etfsListOverlayRenderer,
            excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.Integer],
        }),
        [etfsListOverlayRenderer],
    );

    const aggregationFlows = getAggregationFlows({
        sortDirection: 'desc',
        top: 1000,
        orderBy: dateRangeToFlowToAssetRatioDataPoint[selectedDateRange],
        aggregateBy: categoriesToAggregateField[selectedCategory],
        config: {
            enabled: label === MarketTrend.FlowsToAssetsDetails,
        },
    }) as UseQueryResult<{ data: ETFFlowsToAssetThemesAndFactorsData[] }>;

    const aggregationPerformance = getAggregationPerformance({
        sortDirection: 'desc',
        top: 1000,
        orderBy: dateRangeToReturnSplitAndDividendDataPoint[selectedDateRange],
        aggregateBy: categoriesToAggregateField[selectedCategory],
        config: {
            enabled: label === MarketTrend.PerformanceDetails,
        },
    }) as UseQueryResult<{ data: ETFPerformanceThemesAndFactorsData[] }>;

    const tickerExchanges: Array<{
        ticker: string;
        exchange: string;
    }> = useMemo(() => {
        if (label === MarketTrend.KeyAssetClassesDetails) {
            return keyAssetClassesTickerExchanges;
        }

        if (label === MarketTrend.SectorPerformanceDetails) {
            return SectorPerformanceTickerExchanges;
        }

        return [];
    }, [label]);

    const keyAssetClassesOrSectorPerformanceData = getKeyAssetClassesData({
        tickerExchanges: tickerExchanges,
        config: {
            enabled: tickerExchanges.length > 0,
        },
    });

    const columnDefs = useMemo(() => {
        switch (label) {
            case MarketTrend.FlowsToAssetsDetails: {
                return [
                    ...dynamicColumnDefs,
                    {
                        headerName: `${marketTrendsDateRangesToDisplayString[selectedDateRange]} Flows ($M)`,
                        field: dateRangeToNetFlowsDataPoint[selectedDateRange],
                        valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.MillionsWithForceScale),
                        type: 'rightAligned',
                        excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.MillionsWithForceScale],
                    },
                    {
                        headerName: 'Total Assets ($M)',
                        field: 'total_net_assets',
                        valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.MillionsWithForceScale),
                        type: 'rightAligned',
                        excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.MillionsWithForceScale],
                    },
                    {
                        headerName: `${marketTrendsDateRangesToDisplayString[selectedDateRange]} Flows to Asset`,
                        field: dateRangeToFlowToAssetRatioDataPoint[selectedDateRange],
                        sort: 'desc' as const,
                        valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.Percentage),
                        type: 'rightAligned',
                        excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.Percentage],
                    },
                    numberOfEtfsColumnDef,
                ];
            }
            case MarketTrend.PerformanceDetails: {
                return [
                    ...dynamicColumnDefs,
                    etfNameColumnDef,
                    tickerColumnDef,
                    {
                        headerName: `${marketTrendsDateRangesToDisplayString[selectedDateRange]} Return`,
                        field: dateRangeToReturnSplitAndDividendDataPoint[selectedDateRange],
                        sort: 'desc' as const,
                        valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.Percentage),
                        type: 'rightAligned',
                        excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.Percentage],
                    },
                    numberOfEtfsColumnDef,
                ];
            }
            case MarketTrend.KeyAssetClassesDetails: {
                return [
                    {
                        headerName: 'Asset Class',
                        field: 'asset_class',
                        sort: 'asc' as const,
                    },
                    etfNameColumnDef,
                    tickerColumnDef,
                    ...performanceColumnsDefs,
                    ...flowsColumnsDefs,
                ];
            }
            case MarketTrend.SectorPerformanceDetails: {
                return [
                    { headerName: 'Sector', field: 'sector', sort: 'asc' as const },
                    etfNameColumnDef,
                    tickerColumnDef,
                    ...performanceColumnsDefs,
                    ...flowsColumnsDefs,
                ];
            }
            default: {
                throw new Error(`${label} doesn't exist.`);
            }
        }
    }, [label, selectedDateRange, etfNameColumnDef, tickerColumnDef, numberOfEtfsColumnDef, dynamicColumnDefs]);

    const [breadcrumbsConfig, setBreadcrumbsConfig] = useState<BreadcrumbConfig[] | undefined>(undefined);

    useEffect(() => {
        switch (label) {
            case MarketTrend.KeyAssetClassesDetails: {
                setRowsData(
                    (keyAssetClassesOrSectorPerformanceData?.data?.data as KeyAssetClassesData[] | undefined)?.map(
                        (row) => ({
                            ...row,
                            asset_class: keyAssetClassesTickerDisplayName[row.composite_ticker],
                        }),
                    ),
                );
                break;
            }
            case MarketTrend.SectorPerformanceDetails: {
                setRowsData(
                    (keyAssetClassesOrSectorPerformanceData?.data?.data as KeyAssetClassesData[] | undefined)?.map(
                        (row) => ({
                            ...row,
                            sector: SectorPerformanceTickerDisplayName[row.composite_ticker],
                        }),
                    ),
                );
                break;
            }
            case MarketTrend.FlowsToAssetsDetails:
                setRowsData(aggregationFlows?.data?.data);
                break;
            case MarketTrend.PerformanceDetails:
                setRowsData(aggregationPerformance?.data?.data);
                break;
        }
    }, [
        label,
        aggregationFlows?.data?.data,
        aggregationPerformance?.data?.data,
        keyAssetClassesOrSectorPerformanceData?.data?.data,
    ]);

    useEffect(() => {
        if (drillDownProps) {
            setRowsData((previousValue) =>
                previousValue?.filter((row) => {
                    if (drillDownProps.categoryOne) {
                        return (
                            row[categoriesToResponceCategoryField[Categories.CategoryOne]] ===
                            drillDownProps.categoryOne
                        );
                    }
                    if (drillDownProps.assetClass) {
                        return (
                            row[categoriesToResponceCategoryField[Categories.AssetClass]] === drillDownProps.assetClass
                        );
                    }
                    throw new Error("Can't find categoryOne or assetClass value for the drill down.");
                }),
            );

            const categoryBreadcrumbText =
                (!drillDownProps.categoryOne ? `${Categories.AssetClass}: ` : '') + drillDownProps.assetClass;

            setBreadcrumbsConfig([
                {
                    text: Categories.AssetClass,
                    callback: () => {
                        globalThis.analytics?.registerAction?.({
                            action: `ag grid breadcrumbs : ${Categories.AssetClass}`,
                            cardName: label,
                            dateRange: selectedDateRange,
                        });
                        setDrillDownProps(null);
                        setSelectedCategory(Categories.AssetClass);
                    },
                },
                {
                    text: categoryBreadcrumbText,
                    callback: () => {
                        globalThis.analytics?.registerAction?.({
                            action: `ag grid breadcrumbs : ${categoryBreadcrumbText}`,
                            cardName: label,
                            dateRange: selectedDateRange,
                        });
                        setDrillDownProps({
                            categoryOne: '',
                            assetClass: drillDownProps.assetClass,
                        });
                        setSelectedCategory(getNextLevelCategory(Categories.AssetClass));
                    },
                },
            ]);

            if (drillDownProps.categoryOne) {
                setBreadcrumbsConfig((previousValue) => [
                    ...(previousValue || []),
                    {
                        text: `${Categories.CategoryOne}: ${drillDownProps.categoryOne}`,
                    },
                ]);
            }
        }
    }, [drillDownProps, rowsData, label, selectedDateRange, setSelectedCategory]);

    useEffect(() => {
        switch (label) {
            case MarketTrend.FlowsToAssetsDetails: {
                prefetchData({
                    selectedCategory,
                    selectedDateRange,
                    prefetchFunction: ({ category, dateRange }: prefetchFunctionProps) =>
                        getAggregationFlows({
                            sortDirection: 'desc' as const,
                            orderBy: dateRangeToFlowToAssetRatioDataPoint[dateRange],
                            aggregateBy: categoriesToAggregateField[category],
                            top: 1000,
                            usePrefetchQuery: true,
                        }),
                });
                break;
            }
            case MarketTrend.PerformanceDetails: {
                prefetchData({
                    selectedCategory,
                    selectedDateRange,
                    prefetchFunction: ({ category, dateRange }: prefetchFunctionProps) =>
                        getAggregationPerformance({
                            sortDirection: 'desc' as const,
                            orderBy: dateRangeToReturnSplitAndDividendDataPoint[dateRange],
                            aggregateBy: categoriesToAggregateField[category],
                            top: 1000,
                            usePrefetchQuery: true,
                        }),
                });
                break;
            }
        }
    }, [label, selectedCategory, selectedDateRange]);

    const resultQuery = useMemo(() => {
        switch (label) {
            case MarketTrend.FlowsToAssetsDetails:
                return aggregationFlows;
            case MarketTrend.PerformanceDetails:
                return aggregationPerformance;
            case MarketTrend.KeyAssetClassesDetails:
            case MarketTrend.SectorPerformanceDetails:
                return keyAssetClassesOrSectorPerformanceData;
        }
    }, [label, aggregationFlows, aggregationPerformance, keyAssetClassesOrSectorPerformanceData]);

    const resultJsx = useMemo(() => {
        if (resultQuery?.isLoading) {
            return (
                <CardWithAgGridTopPanel
                    containerStyles={containerStyles}
                    dateRangesPanelConfig={dateRangesPanelConfig}
                    categoriesPanelConfig={categoriesPanelConfig}
                    breadcrumbsConfig={breadcrumbsConfig}>
                    <ETFCard.ETFCard isLoading={true} />
                </CardWithAgGridTopPanel>
            );
        }

        if (!resultQuery?.isLoading && !resultQuery?.data) {
            return (
                <CardWithAgGridTopPanel
                    containerStyles={containerStyles}
                    dateRangesPanelConfig={dateRangesPanelConfig}
                    categoriesPanelConfig={categoriesPanelConfig}
                    breadcrumbsConfig={breadcrumbsConfig}>
                    <NoInformationAvailable sx={{ width: '100%' }} />
                </CardWithAgGridTopPanel>
            );
        }

        return (
            <>
                {rowsData && rowsData.length > 0 && (
                    <AgGridCard
                        ref={gridRef}
                        containerStyles={containerStyles}
                        columnDefs={columnDefs}
                        rowsData={rowsData}
                        dateRangesPanelConfig={dateRangesPanelConfig}
                        categoriesPanelConfig={categoriesPanelConfig}
                        breadcrumbsConfig={breadcrumbsConfig}
                        customFlexibleColumns={customFlexibleColumns}
                        getResizableMinWidthForColumn={getMinWidthForHeader}
                        defaultMaxWidth={350}
                    />
                )}

                {rowsData && rowsData.length === 0 && (
                    <CardWithAgGridTopPanel
                        containerStyles={containerStyles}
                        dateRangesPanelConfig={dateRangesPanelConfig}
                        categoriesPanelConfig={categoriesPanelConfig}
                        breadcrumbsConfig={breadcrumbsConfig}>
                        <NoInformationAvailable sx={{ width: '100%' }} />
                    </CardWithAgGridTopPanel>
                )}
            </>
        );
    }, [rowsData, columnDefs, dateRangesPanelConfig, categoriesPanelConfig, breadcrumbsConfig, resultQuery]);

    return resultJsx;
});
