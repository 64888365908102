import { useEffect } from 'react';

export const useScript = (url: string) => {
    useEffect(() => {
        const script = document.createElement('script');

        if (url) {
            script.src = url;
            script.async = true;
        } else {
            console.error(`Passed invalid url to the useScript custom hook. url: ${url}`);
        }

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [url]);
};
