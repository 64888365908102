import { ETFLinkButton } from '@cfra-nextgen-frontend/shared';
import ETFModal from '@cfra-nextgen-frontend/shared/src/components/ETFModal/index';
import { SxProps } from '@mui/material';
import { useCallback } from 'react';
import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { useRef } from 'react';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';

export type ReadMoreCoreProps = {
    text: string;
    modalTitle: string;
    textLengthToShowReadMore: number;
    linkText?: string;
    linkSx?: SxProps;
    outerGetOpenModalButton?: (handleOpen: () => void) => JSX.Element;
    showDots?: boolean;
    maxWidth?: string;
};

export function ReadMoreCore({
    text,
    modalTitle,
    textLengthToShowReadMore,
    linkText,
    linkSx,
    outerGetOpenModalButton,
    showDots = false,
    maxWidth = '1400px',
}: ReadMoreCoreProps) {
    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);

    const getOpenModalButton = useCallback(
        (handleOpen: () => void) => {
            const _handleOpen = () => {
                analyticsDataPickerRef.current?.registerAction({
                    action: joinWithDelimiter({
                        values: ['open modal', modalTitle],
                    }),
                });
                handleOpen();
            };

            return (
                outerGetOpenModalButton?.(_handleOpen) || (
                    <ETFLinkButton onClick={_handleOpen} text={linkText || ''} sx={linkSx} />
                )
            );
        },
        [linkText, linkSx, outerGetOpenModalButton, modalTitle],
    );

    return (
        <>
            <AnalyticsDataPicker ref={analyticsDataPickerRef} />
            {text.length <= textLengthToShowReadMore ? (
                text
            ) : (
                <>
                    {text.substring(0, text.lastIndexOf(' ', textLengthToShowReadMore) + 1) + (showDots ? '... ' : '')}
                    <ETFModal
                        getOpenComponent={getOpenModalButton}
                        title={modalTitle}
                        description={text}
                        modalBoxStyles={{
                            display: 'block',
                            maxWidth: maxWidth,
                            maxHeight: 'none',
                            overflowY: 'hidden',
                        }}
                        descriptionContainerStyle={{
                            maxHeight: 'calc(100vh - 200px)',
                            overflowY: 'auto',
                        }}
                    />
                </>
            )}
        </>
    );
}
