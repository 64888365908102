import { ETFCard } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { SxProps } from '@mui/material';
import { AgGridCardTopPanel, TopPanelProps } from './AgGridCard';

export const CardWithAgGridTopPanel = ({
    children,
    containerStyles,
    ...props
}: { children: React.ReactNode; containerStyles?: SxProps } & TopPanelProps) => (
    <ETFCard containerStyles={{ padding: '0px', ...containerStyles }}>
        <AgGridCardTopPanel {...props} />
        {children}
    </ETFCard>
);
