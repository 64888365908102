import { DataDisplay } from '@cfra-nextgen-frontend/shared';
import { AnalyticsDataContextProvider } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { tooltipThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { HorizontalPagePaddingString, MaxPageWidthString } from '@cfra-nextgen-frontend/shared/src/utils/lookAndFeel';
import { ScrollingContext, animateScrollToTop } from '@cfra-nextgen-frontend/shared/src/utils/scrolling';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, Stack, ThemeProvider } from '@mui/material';
import { useContext, useRef } from 'react';
import logo from '../../assets/images/CFRA White Logo.svg';
import { Locations } from '../../utils/enums';
import './Footer.scss';

const Footer = () => {
    const { setGoingUp, setActiveTab } = useContext(ScrollingContext);
    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);

    return (
        <AnalyticsDataContextProvider
            cfraDataLocal={{
                actionData: {
                    cardName: Locations.Footer,
                },
            }}>
            <AnalyticsDataPicker ref={analyticsDataPickerRef} />
            <div className='etf-insights-footer'>
                <div className='footer-wrapper' style={{ maxWidth: MaxPageWidthString }}>
                    <ThemeProvider theme={tooltipThemeV2}>
                        <DataDisplay.Tooltip title='Back to Top' placement='top-start' arrow>
                            <button
                                className='btn'
                                style={{ marginRight: HorizontalPagePaddingString }}
                                onClick={() => {
                                    (
                                        analyticsDataPickerRef.current?.registerAction ||
                                        globalThis.analytics?.registerAction
                                    )?.({
                                        action: 'footer : back to top',
                                    });
                                    setGoingUp(true);
                                    setActiveTab(0);
                                    animateScrollToTop();
                                }}>
                                <ArrowUpwardIcon />
                            </button>
                        </DataDisplay.Tooltip>
                    </ThemeProvider>
                    <Stack
                        sx={{
                            py: 4.5,
                            paddingLeft: HorizontalPagePaddingString,
                            paddingRight: '20px',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            '@media (max-width:700px)': {
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                            },
                        }}>
                        <img src={logo} className='logo' alt='logo' />
                        <Stack
                            sx={{ pt: 0, '@media (max-width:700px)': { pt: 1.5 } }}
                            direction='row'
                            alignItems='center'
                            spacing={2}>
                            <a
                                className='a1'
                                href='https://www.cfraresearch.com/legal/'
                                target='_blank'
                                onClick={() =>
                                    (
                                        analyticsDataPickerRef.current?.registerAction ||
                                        globalThis.analytics?.registerAction
                                    )?.({
                                        action: 'footer : legal notice and privacy policy',
                                    })
                                }
                                rel='noreferrer'>
                                Legal Notice and Privacy Policy
                            </a>
                            <hr className='vr' />
                            <a
                                className='a2'
                                href='https://www.cfraresearch.com/contact/'
                                target='_blank'
                                onClick={() =>
                                    (
                                        analyticsDataPickerRef.current?.registerAction ||
                                        globalThis.analytics?.registerAction
                                    )?.({
                                        action: 'footer : contact us',
                                    })
                                }
                                rel='noreferrer'>
                                Contact Us
                            </a>
                        </Stack>
                    </Stack>
                </div>
                <div className='year'>
                    <Box
                        sx={{
                            height: '36px',
                            backgroundColor: '#f2f5fc',
                            color: '#76818c',
                            fontSize: '14px',
                            fontFamily: 'GraphikRegular',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            '@media (max-width:700px)': { height: 'auto' },
                        }}>
                        <p>
                            {' '}
                            Copyright © {new Date().getFullYear()} CFRA.{' '}
                            <span>
                                All rights reserved. All trademarks mentioned herein belong to their respective owners.
                            </span>{' '}
                        </p>
                    </Box>

                    <hr className='divider'></hr>
                </div>
            </div>
        </AnalyticsDataContextProvider>
    );
};

export default Footer;
